import Img from 'gatsby-image'
import { Link } from 'gatsby-plugin-intl'
import React from 'react'
import { FaEdit } from 'react-icons/fa'

// React component for the "BlogMain" section, which is a gallery of news articles. it is a grid of cards with a title, a description and a thumbnail image.
const SectionBlogMain = ({ theme, block, isAdmin, section_id }) => {
    return (
        <section id={section_id} className="block features-block bg-accent outer">
            <div className="inner">
                <div className="grid">
                    <div className="cell block-content">
                        {block.title && block.title.substring(0, 2) !== '__' && <h2 className="block-title underline">{block.title}</h2>}
                        <div className="block-image zoomable-items">
                            <div className="grid">
                                {block.articles.map((article, index) => {
                                    if (article.node.slug.indexOf('untitled-entry') !== -1) return null

                                    return (
                                        <div
                                            key={`article_blog_main_${index}`}
                                            className="card card-container cell block-image zoomable-item"
                                            style={{ float: 'left', width: 'calc(33% - 48px)', margin: 24, minHeight: 300, minWidth: 300 }}
                                        >
                                            <div className="card-content">
                                                <Link to={`/notizie-ed-eventi/${article.node.slug}`} style={{ display: 'block', height: '100%' }}>
                                                    <Img fluid={article.node.firstImage.fluid} />
                                                    <h3>{article.node.title}</h3>
                                                    <h6>{article.node.shortName}</h6>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionBlogMain
