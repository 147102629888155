import _ from 'lodash'
import React from 'react'

import Img from 'gatsby-image'
// import { GatsbyImage, getImage } from "gatsby-plugin-image"

// import heroStyles from "../components/hero.module.css";

export default class SectionImage extends React.Component {
    render() {
        const post = this.props.block

        let content = [
            <div className="cell block-content" style={{ margin: 'auto', maxHeight: 430, height: 430, padding: 0 }}>
                {post.image && (
                    <Img
                        alt={post.title}
                        fluid={post.image ? post.image.fluid : null}
                        imgStyle={{ objectFit: 'contain' }}
                        style={{ maxHeight: '100%' }}
                    />
                )}
            </div>,
            <div className="cell block-content" style={{ textAlign: 'center', margin: 'auto', padding: 0 }}>
                {post.body && post.body.childMarkdownRemark.html !== '' && (
                    <div className="block-copy">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.body.childMarkdownRemark.html,
                            }}
                        />
                    </div>
                )}
            </div>,
        ]

        return (
            <section id={_.get(this.props, 'section_id', null)} className="block features-block bg-null outer">
                <div className="inner">
                    <h2 className="block-left-title" style={{ paddingLeft: 32 }}>
                        {post.title}
                    </h2>

                    <div style={{ height: 460, overflow: 'hidden' }}>{content}</div>
                </div>
                <div className="inner">
                    {post.separator && (
                        <Img
                            style={{ marginTop: 62, height: 332, width: '100%', objectFit: 'cover' }}
                            alt={post.title}
                            fluid={post.separator.fluid}
                        />
                    )}
                    {post.separatorExtra && (
                        <Img
                            style={{ position: 'absolute', top: 1120, left: 1020, objectFit: 'cover', width: 425, height: 294 }}
                            alt={post.title}
                            fluid={post.separatorExtra.fluid}
                        />
                    )}
                </div>
            </section>
        )
    }
}
