import BackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby-plugin-intl'
import React from 'react'
import { FaEdit } from 'react-icons/fa'
import LinkOrAction from './LinkOrAction'
import { usePalette } from 'react-palette'
import { EmbeddedPost, Page, Feed, ShareButton, Like, Share } from 'react-facebook'

const SectionFacebook = ({ theme, block, isAdmin, section_id }) => {
    let content = null

    switch (block.type) {
        default:
        case 'post':
            content = <EmbeddedPost href={block.url} width="500" />
            // content = (
            //     <div class="fb-post" data-href="https://www.facebook.com/20531316728/posts/10154009990506729/" data-width="500" data-show-text="true">
            //         <blockquote cite="https://www.facebook.com/20531316728/posts/10154009990506729/" class="fb-xfbml-parse-ignore">
            //             Pubblicato da <a href="https://www.facebook.com/facebookapp/">Facebook App</a> su&nbsp;
            //             <a href="https://www.facebook.com/20531316728/posts/10154009990506729/">Giovedì 27 agosto 2015</a>
            //         </blockquote>
            //     </div>
            // )
            break
        case 'profile':
            content = (
                <Feed link={block.url}>
                    {({ handleClick }) => (
                        <button type="button" onClick={handleClick}>
                            Share on Feed
                        </button>
                    )}
                </Feed>
            )
            break
        case 'page':
            content = <Page href={block.url} tabs="timeline" />
            break
        case 'share':
            content = <ShareButton href={block.url}>Share</ShareButton>
            break
        case 'sharepost':
            content = (
                <Share href={block.url}>
                    {({ handleClick, loading }) => (
                        <button type="button" disabled={loading} onClick={handleClick}>
                            Share
                        </button>
                    )}
                </Share>
            )
            break
        case 'like':
            content = <Like href={block.url} colorScheme="dark" showFaces share />
            break
    }

    return (
        <section
            id={section_id}
            className="block hero-block bg-accent outer"
            style={{
                overflow: 'hidden',
            }}
        >
            {isAdmin && (
                <Link
                    style={{ position: 'absolute', top: 16, right: 16 }}
                    to={`https://app.contentful.com/spaces/dmb5ihmit9yv/entries/${block.contentful_id}`}
                >
                    <FaEdit /> Edit
                </Link>
            )}
            {content}
        </section>
    )
}

export default SectionFacebook
