import Img from 'gatsby-image'
import React from 'react'

// React component for the "VetrinaFoto" section, which is a gallery of images
// with a title and a description.
const SectionVetrinaFoto = ({ theme, block, isAdmin, section_id }) => {
    return (
        <section id={section_id} className="block features-block bg-accent outer">
            <div className="inner">
                <div className="grid">
                    <div className="cell block-content">
                        {block.title && block.title.substring(0, 2) !== '__' && <h2 className="block-title underline">{block.title}</h2>}
                        <div className="block-image zoomable-items">
                            <div className="grid">
                                <div className="cell block-image zoomable-item" style={{ float: 'left', width: 'calc(33% - 48px)', margin: 24 }}>
                                    <Img fluid={block.immagineASinistra.fluid} />
                                </div>
                                <div className="cell block-image zoomable-item" style={{ float: 'left', width: '33%' }}>
                                    <Img fluid={block.immagineAlCentro.fluid} />
                                </div>
                                <div className="cell block-image zoomable-item" style={{ float: 'left', width: 'calc(33% - 48px)', margin: 24 }}>
                                    <Img fluid={block.immagineADestra.fluid} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionVetrinaFoto
