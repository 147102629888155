import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import AzLayout from '../components/AzLayout'

// import heroStyles from "../components/hero.module.css";
import { FacebookProvider } from 'react-facebook'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Section4Panel from '../components/Section4Panel'
import SectionBlogMain from '../components/SectionBlogMain'
import SectionCalendar from '../components/SectionCalendar'
import SectionCarousel from '../components/SectionCarousel'
import SectionClearText from '../components/SectionClearText'
import SectionContacts from '../components/SectionContacts'
import SectionDoubleText from '../components/SectionDoubleText'
import SectionFacebook from '../components/SectionFacebook'
import SectionFooter from '../components/SectionFooter'
import SectionHero from '../components/SectionHero'
import SectionImage from '../components/SectionImage'
import SectionImageText from '../components/SectionImageText'
import SectionInstagram from '../components/SectionInstagram'
import SectionMap from '../components/SectionMap'
import SectionNewsletter from '../components/SectionNewsletter'
import SectionRecentPosts from '../components/SectionRecentPosts'
import SectionSocialMedia from '../components/SectionSocialMedia'
import SectionTwitch from '../components/SectionTwitch'
import SectionTwitter from '../components/SectionTwitter'
import SectionVetrinaFoto from '../components/SectionVetrinaFoto'
import SectionYoutubeVideo from '../components/SectionYoutubeVideo'
import SectionBlogList from '../components/SectionBlogList'

const themes = ['main', 'alt']

const appId = '456538982275305'

class LandingTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isAdmin: false,
        }
    }

    render() {
        const post = get(this.props, 'data.contentfulLanding')
        const siteTitle = get(this.props, 'data.site.siteMetadata.title')
        const theme = themes[0]

        let { isAdmin } = this.state

        const blogposts = this.props.data.allContentfulBlogPost.edges
        const isEditing = this.props.data.allContentfulGlobalSettings.edges[0].node.editing
        if (isEditing) isAdmin = true

        let header = {}
        let footer = {}
        if (this.props.data.allContentfulNavigation) {
            for (const edge of this.props.data.allContentfulNavigation.edges) {
                const block = edge.node
                if (block.links && block.links.length > 0 && block.links[0].node_locale === this.props.pageContext.language) {
                    if (block.type === 'header') {
                        header = block
                    }
                    if (block.type === 'footer') {
                        footer = block
                    }
                }
            }
        }

        header.data = { site: this.props.data.site, url: this.props.data.url }
        footer.data = { site: this.props.data.site, url: this.props.data.url }

        header.subMenu = this.props.data.contentfulLanding.subMenu

        const content = []
        let index = 0
        if (this.props.data.contentfulLanding.landingBlocks) {
            for (const block of this.props.data.contentfulLanding.landingBlocks) {
                if (!block.internal || block.hidden) continue
                const type = block.internal.type
                switch (type) {
                    case 'ContentfulAuthor':
                        content.push(<span>Missing author</span>)
                        break
                    case 'ContentfulHero':
                        content.push(
                            <SectionHero isAdmin={isAdmin} key={index} {...{ ...this.props, section_id: 'hero' }} block={block} theme={theme} />
                        )
                        break
                    case 'ContentfulImageText':
                        content.push(<SectionImageText isAdmin={isAdmin} key={index} {...this.props} block={block} theme={theme} />)
                        break
                    case 'ContentfulImageOnly':
                        content.push(<SectionImage isAdmin={isAdmin} key={index} {...this.props} block={block} theme={theme} />)
                        break
                    case 'ContentfulClearText':
                        content.push(<SectionClearText isAdmin={isAdmin} key={index} {...this.props} block={block} theme={theme} />)
                        break
                    case 'ContentfulDoubleText':
                        content.push(<SectionDoubleText isAdmin={isAdmin} key={index} {...this.props} block={block} theme={theme} />)
                        break
                    case 'ContentfulRecentPosts':
                        content.push(<SectionRecentPosts isAdmin={isAdmin} key={index} {...this.props} block={block} theme={theme} />)
                        break
                    case 'ContentfulVetrinaFoto':
                        content.push(<SectionVetrinaFoto isAdmin={isAdmin} key={index} {...this.props} block={block} theme={theme} />)
                        break
                    case 'ContentfulNewsletter':
                        content.push(<SectionNewsletter isAdmin={isAdmin} key={index} {...this.props} block={block} theme={theme} />)
                        break
                    case 'ContentfulContacts':
                        content.push(<SectionContacts isAdmin={isAdmin} key={index} {...this.props} block={block} theme={theme} />)
                        break
                    case 'ContentfulSocialMedia':
                        content.push(<SectionSocialMedia isAdmin={isAdmin} key={index} {...this.props} block={block} theme={theme} />)
                        break
                    case 'ContentfulCarousel':
                        content.push(<SectionCarousel isAdmin={isAdmin} key={index} {...this.props} block={block} theme={theme} />)
                        break
                    case 'ContentfulFooter':
                        content.push(<SectionFooter isAdmin={isAdmin} key={index} {...this.props} block={block} theme={theme} />)
                        break
                    case 'ContentfulTwitch':
                        content.push(<SectionTwitch key={index} {...this.props} block={block} theme={theme} />)
                        break
                    case 'ContentfulYoutubeVideo':
                        content.push(<SectionYoutubeVideo key={index} {...this.props} block={block} theme={theme} />)
                        break
                    case 'ContentfulFacebook':
                        content.push(<SectionFacebook key={index} {...this.props} block={block} theme={theme} />)
                        break
                    case 'ContentfulInstagramPost':
                        content.push(<SectionInstagram key={index} {...this.props} block={block} theme={theme} />)
                        break
                    case 'ContentfulTwitter':
                        content.push(<SectionTwitter key={index} {...this.props} block={block} theme={theme} />)
                        break
                    case 'ContentfulCalendar':
                        content.push(<SectionCalendar key={index} {...this.props} block={block} theme={theme} />)
                        break
                    case 'ContentfulGrid4Elements':
                        content.push(<Section4Panel key={index} {...this.props} block={block} theme={theme} />)
                        break
                    case 'ContentfulBlogMain':
                        block.articles = blogposts
                        content.push(
                            <div className="d-md-none">
                                <SectionBlogList key={index} {...this.props} block={block} theme={theme} />
                            </div>
                        )
                        content.push(
                            <div className="d-none d-md-block">
                                <SectionBlogMain key={index} {...this.props} block={block} theme={theme} />
                            </div>
                        )
                        break
                    case 'ContentfulMap':
                        content.push(
                            <SectionMap key={index} {...{ ...this.props, section_id: 'features', features: [block] }} block={block} theme={theme} />
                        )
                        break
                    case 'ContentfulNavigation':
                        if (block.type === 'footer') {
                            content.push(<Footer isAdmin={isAdmin} key={index} data={this.props.data} {...block} block={block} theme={theme} />)
                        }
                    default:
                        break
                }
                index++
            }
        }

        return (
            <FacebookProvider appId={appId}>
                <AzLayout {...this.props} siteClass="site-content">
                    <Header isAdmin={isAdmin} {...header} />
                    {content}
                </AzLayout>
            </FacebookProvider>
        )
    }
}

export default LandingTemplate

export const pageQuery = graphql`
    query LandingBySlug($locale: String, $slug: String!) {
        allContentfulBlogPost(limit: 50, sort: { fields: publishDate, order: DESC }) {
            edges {
                node {
                    id
                    author {
                        email
                        company
                        contentful_id
                        createdAt
                        facebook
                        github
                        name
                        phone
                        title
                        twitter
                    }
                    body {
                        childMarkdownRemark {
                            id
                            html
                        }
                    }
                    firstImage {
                        fluid(maxHeight: 300, maxWidth: 800, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                        file {
                            fileName
                        }
                    }
                    slug
                    publishDate
                    shortName
                    title
                }
            }
        }
        allContentfulGlobalSettings {
            edges {
                node {
                    contentful_id
                    id
                    editing
                }
            }
        }
        allContentfulNavigation {
            edges {
                node {
                    contentful_id
                    id
                    type
                    node_locale
                    links {
                        id
                        title
                        node_locale
                        shortName
                        navButtonType
                        slug
                    }
                    logo {
                        fluid(maxWidth: 1180, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }
        contentfulLanding(node_locale: { eq: $locale }, slug: { eq: $slug }) {
            title
            publishDate(formatString: "MMMM Do, YYYY")
            subMenu {
                links {
                    id
                    title
                    node_locale
                    shortName
                    slug
                    navButtonType
                }
            }
            landingBlocks {
                ... on ContentfulNavigation {
                    contentful_id
                    type
                    node_locale
                    logo {
                        fluid(maxWidth: 1180, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                    }
                    links {
                        title
                        node_locale
                        shortName
                        slug
                        navButtonType
                    }
                    title
                    internal {
                        type
                    }
                }
                ... on ContentfulSocialMedia {
                    contentful_id
                    title
                    facebook
                    linkedin
                    twitter
                    instagram
                    createdAt
                    updatedAt
                    internal {
                        type
                    }
                }
                ... on ContentfulTwitch {
                    contentful_id
                    channel
                    internal {
                        type
                    }
                }
                ... on ContentfulYoutubeVideo {
                    contentful_id
                    title
                    videoId
                    internal {
                        type
                    }
                }
                ... on ContentfulFacebook {
                    contentful_id
                    title
                    type
                    url
                    internal {
                        type
                    }
                }
                ... on ContentfulInstagramPost {
                    contentful_id
                    title
                    url
                    internal {
                        type
                    }
                }
                ... on ContentfulTwitter {
                    contentful_id
                    title
                    type
                    referenceId
                    shareUrl
                    shareContent
                    internal {
                        type
                    }
                }
                ... on ContentfulCalendar {
                    contentful_id
                    title
                    internal {
                        type
                    }
                    fissoLunedi
                    fissoMartedi
                    fissoMercoledi
                    fissoGiovedi
                    fissoVenerdi
                    fissoSabato
                    fissoDomenica
                }
                ... on ContentfulBlogMain {
                    contentful_id
                    nomeInterno
                    internal {
                        type
                    }
                }
                ... on ContentfulGrid4Elements {
                    contentful_id
                    title
                    internal {
                        type
                    }
                    topLeft {
                        fluid(maxWidth: 1180, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                        fixed(height: 332, width: 332) {
                            ...GatsbyContentfulFixed
                        }
                    }
                    topRight {
                        fluid(maxWidth: 1180, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                        fixed(height: 332, width: 332) {
                            ...GatsbyContentfulFixed
                        }
                    }
                    bottomLeft {
                        fluid(maxWidth: 1180, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                        fixed(height: 332, width: 332) {
                            ...GatsbyContentfulFixed
                        }
                    }
                    bottomRight {
                        fluid(maxWidth: 1180, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                        fixed(height: 332, width: 332) {
                            ...GatsbyContentfulFixed
                        }
                    }
                    titleTopLeft
                    titleTopRight
                    titleBottomLeft
                    titleBottomRight
                }
                ... on ContentfulCarousel {
                    contentful_id
                    title
                    images {
                        ... on ContentfulAsset {
                            fluid(maxWidth: 288, background: "rgb:000000") {
                                ...GatsbyContentfulFluid
                            }
                            fixed {
                                ...GatsbyContentfulFixed_tracedSVG
                            }
                        }
                    }
                    internal {
                        type
                    }
                }
                ... on ContentfulImageOnly {
                    contentful_id
                    title
                    createdAt
                    updatedAt
                    image {
                        fluid(maxHeight: 500, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                    }
                    reverse
                    internal {
                        type
                    }
                    body {
                        id
                        childMarkdownRemark {
                            id
                            html
                        }
                    }
                    hidden
                }
                ... on ContentfulImageText {
                    contentful_id
                    title
                    createdAt
                    updatedAt
                    image {
                        fluid(maxWidth: 1180, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                        fixed(height: 332, width: 332) {
                            ...GatsbyContentfulFixed
                        }
                    }
                    reverse
                    internal {
                        type
                    }
                    body {
                        id
                        childMarkdownRemark {
                            id
                            html
                        }
                    }
                    hidden
                }
                ... on ContentfulClearText {
                    contentful_id
                    title
                    type
                    createdAt
                    updatedAt
                    internal {
                        type
                    }
                    body {
                        id
                        childMarkdownRemark {
                            id
                            html
                        }
                    }
                    hidden
                }
                ... on ContentfulDoubleText {
                    contentful_id
                    title
                    createdAt
                    updatedAt
                    internal {
                        type
                    }
                    leftText {
                        id
                        childMarkdownRemark {
                            id
                            html
                        }
                    }
                    rightText {
                        id
                        childMarkdownRemark {
                            id
                            html
                        }
                    }
                    hidden
                }
                ... on ContentfulNewsletter {
                    contentful_id
                    title
                    createdAt
                    updatedAt
                    internal {
                        type
                    }
                    body {
                        id
                        childMarkdownRemark {
                            id
                            html
                        }
                    }
                    placeholder
                    subscribe
                    heroImage {
                        fluid(maxWidth: 1180, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                    }
                    hidden
                }
                ... on ContentfulHero {
                    contentful_id
                    title
                    slug
                    body {
                        childMarkdownRemark {
                            html
                        }
                    }
                    createdAt
                    updatedAt
                    internal {
                        type
                    }
                    heroImage {
                        fluid(maxHeight: 400, maxWidth: 400, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                        file {
                            url
                        }
                        fixed(height: 332, width: 332) {
                            ...GatsbyContentfulFixed
                        }
                    }
                    hidden
                }
                ... on ContentfulVetrinaFoto {
                    contentful_id
                    titoloVetrina
                    internal {
                        type
                    }
                    immagineASinistra {
                        fluid(maxHeight: 400, maxWidth: 400, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                        file {
                            url
                        }
                        fixed(height: 332, width: 332) {
                            ...GatsbyContentfulFixed
                        }
                    }
                    immagineAlCentro {
                        fluid(maxHeight: 400, maxWidth: 400, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                        file {
                            url
                        }
                        fixed(height: 332, width: 332) {
                            ...GatsbyContentfulFixed
                        }
                    }
                    immagineADestra {
                        fluid(maxHeight: 400, maxWidth: 400, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                        file {
                            url
                        }
                        fixed(height: 332, width: 332) {
                            ...GatsbyContentfulFixed
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                base_font
                site_url
                footer {
                    content
                    has_nav
                    nav_links {
                        ... on SiteSiteMetadataFooterNav_links {
                            label
                            style
                            url
                        }
                    }
                    sections {
                        ... on SiteSiteMetadataFooterSections {
                            content
                            form_fields {
                                ... on SiteSiteMetadataFooterSectionsForm_fields {
                                    default_value
                                    input_type
                                    is_required
                                    label
                                    name
                                }
                            }
                            form_id
                            hide_labels
                            image
                            image_alt
                            image_url
                            nav_links {
                                ... on SiteSiteMetadataFooterSectionsNav_links {
                                    label
                                    style
                                    url
                                }
                            }
                            submit_label
                            title
                            type
                        }
                    }
                }
                header {
                    has_nav
                    logo_img
                    logo_img_alt
                    nav_links {
                        ... on SiteSiteMetadataHeaderNav_links {
                            label
                            style
                            url
                        }
                    }
                }
                palette
            }
        }
    }
`
