import { Link } from 'gatsby-plugin-intl'
import React from 'react'
import { FaEdit } from 'react-icons/fa'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { TwitchEmbed } from 'react-twitch-embed'

const SectionTwitch = ({ theme, block, isAdmin, section_id }) => {
    return (
        <section
            id={section_id}
            className="block hero-block bg-accent outer"
            style={{
                overflow: 'hidden',
                padding: 0,
            }}
        >
            {isAdmin && (
                <Link
                    style={{ position: 'absolute', top: 16, right: 16 }}
                    to={`https://app.contentful.com/spaces/dmb5ihmit9yv/entries/${block.contentful_id}`}
                >
                    <FaEdit /> Edit
                </Link>
            )}
            <TwitchEmbed channel={block.channel} id={block.channel} theme="dark" muted withChat width="100%" />
        </section>
    )
}

export default SectionTwitch
