import { Link } from 'gatsby-plugin-intl'
import React from 'react'
import { FaEdit } from 'react-icons/fa'
import InstagramEmbed from 'react-instagram-embed'

const SectionInstagram = ({ theme, block, isAdmin, section_id }) => {
    let content = (
        <InstagramEmbed
            url={block.url}
            clientAccessToken="456538982275305|d5a032c29343239b7409f4a1a860b2a6"
            maxWidth={320}
            hideCaption={false}
            containerTagName="div"
            protocol=""
            injectScript
            onLoading={(a) => {}}
            onSuccess={(a) => {}}
            onAfterRender={(a) => {}}
            onFailure={(a) => {}}
        />
    )

    return (
        <section
            id={section_id}
            className="block hero-block bg-accent outer"
            style={{
                overflow: 'hidden',
                color: 'black',
            }}
        >
            {isAdmin && (
                <Link
                    style={{ position: 'absolute', top: 16, right: 16 }}
                    to={`https://app.contentful.com/spaces/dmb5ihmit9yv/entries/${block.contentful_id}`}
                >
                    <FaEdit /> Edit
                </Link>
            )}
            {content}
        </section>
    )
}

export default SectionInstagram
