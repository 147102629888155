import BackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby-plugin-intl'
import React from 'react'
import { FaEdit } from 'react-icons/fa'
import LinkOrAction from './LinkOrAction'
import { usePalette } from 'react-palette'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

const SectionMap = ({ theme, block, isAdmin, section_id }) => {
    return (
        <section
            id={section_id}
            className="block hero-block bg-accent outer"
            style={{
                overflow: 'hidden',
            }}
        >
            {isAdmin && (
                <Link
                    style={{ position: 'absolute', top: 16, right: 16 }}
                    to={`https://app.contentful.com/spaces/dmb5ihmit9yv/entries/${block.contentful_id}`}
                >
                    <FaEdit /> Edit
                </Link>
            )}

            <img
                src={`https://maps.googleapis.com/maps/api/staticmap?center=${block.googleMapsString}&zoom=13&size=600x300&maptype=roadmap
&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318
&markers=color:red%7Clabel:C%7C40.718217,-73.998284
&key=AIzaSyAfxsb41Ssxue7dSFAszIRcbbss4OAndd0`}
            />
        </section>
    )
}

export default SectionMap
