import { Link } from 'gatsby-plugin-intl'
import React from 'react'
import { FaEdit } from 'react-icons/fa'

const SectionTwitter = ({ theme, block, isAdmin, section_id }) => {
    return (
        <section
            id={section_id}
            className="block hero-block bg-accent outer"
            style={{
                overflow: 'hidden',
            }}
        >
            {isAdmin && (
                <Link
                    style={{ position: 'absolute', top: 16, right: 16 }}
                    to={`https://app.contentful.com/spaces/dmb5ihmit9yv/entries/${block.contentful_id}`}
                >
                    <FaEdit /> Edit
                </Link>
            )}
            <a
                class="twitter-timeline"
                data-lang="it"
                data-width="1200"
                data-height="1200"
                data-theme="dark"
                href={`https://twitter.com/${block.referenceId}?ref_src=twsrc%5Etfw`}
            ></a>
        </section>
    )
}

export default SectionTwitter
