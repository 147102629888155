import Img from 'gatsby-image'
import React from 'react'

// A React component for the "Section4Panel" section, which is a panel with a 4 element grid
const Section4Panel = ({ theme, block, isAdmin, section_id }) => {
    return (
        <section id={section_id} className="block p4grid-block bg-accent outer">
            <div className="inner">
                <div className="grid">
                    <div className="cell block-content">
                        {block.title && block.title.substring(0, 2) !== '__' && <h2 className="block-title underline">{block.title}</h2>}
                        <div className="block-image zoomable-items">
                            <div className="grid">
                                <div className="cell block-image zoomable-item" style={{ float: 'left', width: 'calc(50% - 48px)', margin: 24 }}>
                                    <Img fluid={block.topLeft.fluid} />

                                    <div class="overlay-text-container">
                                        <div class="overlay-text-wrapper">
                                            <h3>{block.titleTopLeft}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell block-image zoomable-item" style={{ float: 'left', width: 'calc(50% - 48px)', margin: 24 }}>
                                    <Img fluid={block.topRight.fluid} />
                                    <div class="overlay-text-container">
                                        <div class="overlay-text-wrapper">
                                            <h3>{block.titleTopRight}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell block-image zoomable-item" style={{ float: 'left', width: 'calc(50% - 48px)', margin: 24 }}>
                                    <Img fluid={block.bottomLeft.fluid} />
                                    <div class="overlay-text-container">
                                        <div class="overlay-text-wrapper">
                                            <h3>{block.titleBottomLeft}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell block-image zoomable-item" style={{ float: 'left', width: 'calc(50% - 48px)', margin: 24 }}>
                                    <Img fluid={block.bottomRight.fluid} />
                                    <div class="overlay-text-container">
                                        <div class="overlay-text-wrapper">
                                            <h3>{block.titleBottomRight}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section4Panel
