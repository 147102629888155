import { Link } from 'gatsby-plugin-intl'
import React from 'react'
import { FaEdit } from 'react-icons/fa'

const SectionCalendar = ({ theme, block, isAdmin, section_id }) => {
    return (
        <section id={section_id} className="block features-block bg-accent outer calendar-block">
            <div className="inner">
                <div className="grid">
                    <div className="cell block-content">
                        {block.title && block.title.substring(0, 2) !== '__' && <h2 className="block-title underline">{block.title}</h2>}
                        <div className="horizontal-scroll">
                            <div className="calendar-column" style={{ marginLeft: 'auto' }}>
                                <h2>Lunedì</h2>
                                <h6 className="calendar-time">{block.fissoLunedi}</h6>
                            </div>
                            <div className="calendar-column">
                                <h2>Martedì</h2>

                                <h6 className="calendar-time">{block.fissoMartedi}</h6>
                            </div>
                            <div className="calendar-column">
                                <h2>Mercoledì</h2>

                                <h6 className="calendar-time">{block.fissoMercoledi}</h6>
                            </div>
                            <div className="calendar-column">
                                <h2>Giovedì</h2>

                                <h6 className="calendar-time">{block.fissoGiovedi}</h6>
                            </div>
                            <div className="calendar-column">
                                <h2>Venerdì</h2>

                                <h6 className="calendar-time">{block.fissoVenerdi}</h6>
                            </div>
                            <div className="calendar-column">
                                <h2>Sabato</h2>

                                <h6 className="calendar-time">{block.fissoSabato}</h6>
                            </div>
                            <div className="calendar-column" style={{ marginRight: 'auto' }}>
                                <h2>Domenica</h2>

                                <h6 className="calendar-time">{block.fissoDomenica}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionCalendar
